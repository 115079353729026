import React from "react"
import Helmet from "react-helmet"
import HeaderImage from "../images/easter_og.jpg"
import TwitterImage from "../images/easter_twitter.jpg"

const EasterHead = () => (
    <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>Easter at Cross Church - 2024</title>
        <meta name="title" content="Easter at Cross Church - 2024" />
        <meta name="description" content="Join us as we celebrate Easter at Cross Church." />
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta
            property="og:url"
            content="https://www.crosschurchcares.com/easter"
        />
        <meta property="og:title" content="Easter at Cross Church - 2024" />
        <meta
            property="og:description"
            content="Join us as we celebrate Easter at Cross Church."
        />
        <meta property="og:image" content={HeaderImage} />
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary" />
        <meta
            property="twitter:url"
            content="https://www.crosschurchcares.com/easter"
        />
        <meta property="twitter:title" content="Easter at Cross Church - 2024" />
        <meta
            property="twitter:description"
            content="Join us as we celebrate Easter at Cross Church."
        />
        <meta property="twitter:image" content={TwitterImage} />
    </Helmet>
)

export default EasterHead
